let constants = {
  pipeline: {
    automationRule: {
      'SURGERY_DATE': 1,
      'CHECKLIST': 2,
      'PIPELINE_STAGE_CHECKLIST_PERCENTAGE_COMPLETION': 4,
      'PATIENT_STAGE_DAYS': 5,
      'SURGERY_DATE_ADDED': 6,
    },
    currentPipelineStageType: {
      'ANY_STAGE': 1,
      'THIS_STAGE': 2,
      'AFTER_THIS_STAGE': 3,
    },
    completedBy: {
      'ANYONE': 1,
      'PATIENT': 2,
      'PROGRAM': 3,
    },
    surgeryDateDuration: {
      'DAILY': 1,
      'WEEKLY': 2,
      'MONTHLY': 3,
      'YEARLY': 4,
    }
  },
  monthPickerLabels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  cardsTrophies: {
    userTierTypes: {
      'non_connected': 'Non-Connected',
      'tier_1': 'Tier 1',
      'tier_2': 'Tier 2',
      'tier_3': 'Tier 3',
    },
    ruleTypes: {
      'food_log': 'Food Log',
      'app_usage': 'App Usage',
      'surgery_date': 'Surgery Date',
      'specific_date': 'Specific Date',
    },
    daysConditions: {
      'in_row': 'In a Row',
      'till_now': 'Till Now',
      'preop': 'Pre-Op',
      'postop': 'Post-Op',
    },
    surgeryDayTypes: {
      'day': 'Day',
      'week': 'Week',
      'month': 'Month',
      'year': 'Year',
    },
  },
  baritasticProgram: {
    tierOptions: [
      {
        label: 'Tier 1',
        value: 1,
      },
      {
        label: 'Tier 2',
        value: 2,
      },
      {
        label: 'Tier 3',
        value: 3,
      }
    ],
    timezoneOptions: [
      {
        label: "Eastern Daylight Time New York (GMT-4)",
        value: "America/New_York"
      },
      {
        label: "Central Daylight Time Chicago (GMT-5)",
        value: "America/Chicago"
      },
      {
        label: "Mountain Daylight Time Denver (GMT-6)",
        value: "America/Denver"
      },
      {
        label: "Mountain Standard Time Phoenix (GMT-7)",
        value: "America/Phoenix"
      },
      {
        label: "Pacific Daylight Time Los Angeles (GMT-7)",
        value: "America/Los_Angeles"
      },
      {
        label: "Alaska Daylight Time Anchorage (GMT-8)",
        value: "America/Anchorage"
      },
      {
        label: "Hawaii-Aleutian Standard Time (GMT-10)",
        value: "Pacific/Honolulu"
      },
      {
        label: "Australian Eastern Standard Time Sydney (GMT+10)",
        value: "Australia/Sydney"
      },
    ]
  },
  programUser: {
    getRole: {
      1: 'Dietitian',
      2: 'Bariatric Coordinator',
      3: 'Director/Operations',
      4: 'Surgery Scheduler',
      5: 'Front Desk',
      6: 'Office Manager',
      7: 'PA',
      8: 'Surgeon',
      9: 'Insurance Coordinator',
      10: 'Psychologist',
      11: 'RN',
      12: 'Other'
    },
    roleOptions: [
      {value: 1, label: 'Dietitian',},
      {value: 2, label: 'Bariatric Coordinator'},
      {value: 3, label: 'Director/Operations'},
      {value: 4, label: 'Surgery Scheduler'},
      {value: 5, label: 'Front Desk'},
      {value: 6, label: 'Office Manager'},
      {value: 7, label: 'PA'},
      {value: 8, label: 'Surgeon'},
      {value: 9, label: 'Insurance Coordinator'},
      {value: 10, label: 'Psychologist'},
      {value: 11, label: 'RN'},
      {value: 12, label: 'Other'}
    ],
  },
  common: {
    statusOptions: [
      {
        label: 'Active',
        value: 1,
      },
      {
        label: 'Inactive',
        value: 0,
      }
    ],
    defaultStages: [
      {label: 'Considering Surgery', value: '25'},
      {label: 'Pre-Op', value: '50'},
      {label: 'Post-Op', value: '75'},
      {label: 'Non-Surgical', value: '150'},
    ],
  }
}
export {constants};
